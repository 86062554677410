exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blurred-logo-tsx": () => import("./../../../src/pages/blurred-logo.tsx" /* webpackChunkName: "component---src-pages-blurred-logo-tsx" */),
  "component---src-pages-border-animation-tsx": () => import("./../../../src/pages/border-animation.tsx" /* webpackChunkName: "component---src-pages-border-animation-tsx" */),
  "component---src-pages-confetti-tsx": () => import("./../../../src/pages/confetti.tsx" /* webpackChunkName: "component---src-pages-confetti-tsx" */),
  "component---src-pages-dock-menu-tsx": () => import("./../../../src/pages/dock-menu.tsx" /* webpackChunkName: "component---src-pages-dock-menu-tsx" */),
  "component---src-pages-dynamic-card-tsx": () => import("./../../../src/pages/dynamic-card.tsx" /* webpackChunkName: "component---src-pages-dynamic-card-tsx" */),
  "component---src-pages-dynamic-island-toc-tsx": () => import("./../../../src/pages/dynamic-island-toc.tsx" /* webpackChunkName: "component---src-pages-dynamic-island-toc-tsx" */),
  "component---src-pages-floating-video-tsx": () => import("./../../../src/pages/floating-video.tsx" /* webpackChunkName: "component---src-pages-floating-video-tsx" */),
  "component---src-pages-genie-window-tsx": () => import("./../../../src/pages/genie-window.tsx" /* webpackChunkName: "component---src-pages-genie-window-tsx" */),
  "component---src-pages-glow-cursor-list-tsx": () => import("./../../../src/pages/glow-cursor-list.tsx" /* webpackChunkName: "component---src-pages-glow-cursor-list-tsx" */),
  "component---src-pages-image-tsx": () => import("./../../../src/pages/image.tsx" /* webpackChunkName: "component---src-pages-image-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-light-bulb-tsx": () => import("./../../../src/pages/light-bulb.tsx" /* webpackChunkName: "component---src-pages-light-bulb-tsx" */),
  "component---src-pages-link-preview-tsx": () => import("./../../../src/pages/link-preview.tsx" /* webpackChunkName: "component---src-pages-link-preview-tsx" */),
  "component---src-pages-particle-effect-tsx": () => import("./../../../src/pages/particle-effect.tsx" /* webpackChunkName: "component---src-pages-particle-effect-tsx" */),
  "component---src-pages-particle-text-tsx": () => import("./../../../src/pages/particle-text.tsx" /* webpackChunkName: "component---src-pages-particle-text-tsx" */),
  "component---src-pages-radial-menu-tsx": () => import("./../../../src/pages/radial-menu.tsx" /* webpackChunkName: "component---src-pages-radial-menu-tsx" */),
  "component---src-pages-random-text-tsx": () => import("./../../../src/pages/random-text.tsx" /* webpackChunkName: "component---src-pages-random-text-tsx" */),
  "component---src-pages-stacked-toast-tsx": () => import("./../../../src/pages/stacked-toast.tsx" /* webpackChunkName: "component---src-pages-stacked-toast-tsx" */),
  "component---src-pages-switch-tab-tsx": () => import("./../../../src/pages/switch-tab.tsx" /* webpackChunkName: "component---src-pages-switch-tab-tsx" */),
  "component---src-pages-tree-view-tsx": () => import("./../../../src/pages/tree-view.tsx" /* webpackChunkName: "component---src-pages-tree-view-tsx" */),
  "component---src-pages-typing-text-tsx": () => import("./../../../src/pages/typing-text.tsx" /* webpackChunkName: "component---src-pages-typing-text-tsx" */)
}

